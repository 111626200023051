.qrcode-modal .ant-modal-content {
  border-radius: 16px;
  overflow: hidden;
}

.qrcode-content {
  text-align: center;
  padding: 20px 0;
}

.qrcode-icon {
  font-size: 36px;
  color: #1890ff;
  margin-bottom: 16px;
}

.qrcode-image {
  width: 200px;
  height: 200px;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.qrcode-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.qrcode-tip {
  color: #666;
  margin-top: 16px;
} 