.home-page {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-section {
  text-align: center;
  padding: 60px 0;
  background: linear-gradient(135deg, #1890ff0d 0%, #722ed108 100%);
  border-radius: 16px;
  margin-bottom: 48px;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 24px;
  background: linear-gradient(120deg, #1890ff, #722ed1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-card {
  height: 100%;
  text-align: center;
  padding: 24px;
  transition: all 0.3s ease;
}

.service-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.service-card .anticon {
  font-size: 36px;
  margin-bottom: 16px;
  color: #1890ff;
}

.statistics-section {
  margin-top: 48px;
  padding: 32px;
  background: #fafafa;
  border-radius: 16px;
}

.statistic-item {
  text-align: center;
}

.ant-statistic-title {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.ant-statistic-content {
  color: #1890ff;
  font-size: 2rem;
  font-weight: bold;
} 