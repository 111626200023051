/* 全局样式重置和基础设置 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f7fa;
}

/* Layout 相关样式 */
.ant-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.ant-layout-content {
  flex: 1;
  padding: 24px;
  background: #f5f7fa;
}

.ant-layout-footer {
  background: #001529;
  color: rgba(255, 255, 255, 0.65);
  padding: 24px 50px;
}

/* 页面通用样式 */
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

/* 标题样式 */
.page-title {
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 32px;
}

.page-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #1890ff, #722ed1);
  border-radius: 2px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .ant-layout-content {
    padding: 16px;
  }
  
  .page-container {
    padding: 20px;
  }
}

.services-page,
.cases-page,
.contact-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.tech-icon {
  font-size: 32px;
  margin-bottom: 16px;
  color: #1890ff;
}

.case-tags {
  margin: 16px 0;
}

.contact-info {
  margin: 24px 0;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.ant-card {
  height: 100%;
}

/* 确保内容区域填充可用空间 */
.home-page,
.services-page,
.cases-page,
.contact-page {
  flex: 1;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Footer 样式优化 */
.ant-layout-footer {
  padding: 24px 50px;
  background: #001529;
  color: rgba(255, 255, 255, 0.65);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .home-page,
  .services-page,
  .cases-page,
  .contact-page {
    padding: 20px;
  }
  
  .ant-layout-footer {
    padding: 16px 20px;
  }
} 