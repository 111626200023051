.about-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.section-icon {
  color: #1890ff;
  margin-right: 12px;
}

/* 公司简介部分 */
.company-intro {
  margin-bottom: 60px;
  text-align: center;
}

.intro-text {
  font-size: 16px;
  line-height: 1.8;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
}

/* 核心价值观部分 */
.values-section {
  margin-bottom: 60px;
}

.value-card {
  text-align: center;
  padding: 24px;
  height: 100%;
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
}

.value-card:hover {
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
}

.value-icon {
  font-size: 36px;
  color: #1890ff;
  margin-bottom: 16px;
  transition: transform 0.3s ease;
}

.value-card:hover .value-icon {
  transform: rotate(360deg);
}

/* 发展历程部分 */
.timeline-section {
  margin-bottom: 60px;
}

.company-timeline {
  margin-top: 40px;
}

.timeline-dot {
  font-size: 16px;
  color: #1890ff;
}

.timeline-card {
  max-width: 300px;
  margin: 0 auto;
  border-radius: 8px;
  background: #fafafa;
}

.timeline-card h4 {
  color: #1890ff;
  margin-bottom: 8px;
}

.timeline-card h5 {
  margin-bottom: 8px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .about-page {
    padding: 20px;
  }

  .timeline-card {
    max-width: 100%;
  }

  .company-timeline {
    margin-top: 20px;
  }
} 