.contact-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  text-align: center;
  margin-bottom: 48px;
}

.page-header h1 {
  font-size: 2.5em;
  background: linear-gradient(120deg, #1890ff, #722ed1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-desc {
  font-size: 1.2em;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.contact-card {
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.contact-card:hover {
  box-shadow: 0 12px 24px rgba(0,0,0,0.1);
}

.info-card {
  text-align: center;
  padding: 24px;
  height: 100%;
  transition: all 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px);
}

.info-icon {
  font-size: 36px;
  margin-bottom: 16px;
}

.quick-contact {
  text-align: center;
  padding: 24px;
  background: linear-gradient(145deg, #ffffff, #f0f2f5);
}

.wechat-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wechat-icon {
  font-size: 48px;
  color: #07c160;
  margin-bottom: 16px;
}

.qrcode-wrapper {
  margin-top: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.qrcode-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.qrcode-wrapper img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.qrcode-tip {
  margin-top: 16px;
  color: #666;
  font-size: 14px;
}

.contact-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.contact-buttons .ant-btn {
  height: 48px;
  font-size: 16px;
}

.advantages-card {
  margin-top: 24px;
  border-radius: 12px;
}

.section-title {
  text-align: center;
  margin-bottom: 32px;
}

.advantage-item {
  text-align: center;
  padding: 24px;
  transition: all 0.3s ease;
}

.advantage-item:hover {
  transform: translateY(-5px);
}

.advantage-icon {
  font-size: 48px;
  color: #1890ff;
  margin-bottom: 16px;
}

.advantage-item h4 {
  margin-bottom: 12px;
}

/* 动画效果 */
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.info-icon {
  animation: float 3s ease-in-out infinite;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .contact-page {
    padding: 20px;
  }

  .page-header h1 {
    font-size: 2em;
  }

  .page-desc {
    font-size: 1em;
  }

  .info-card {
    padding: 16px;
  }
} 