/* 淡入动画 */
.fade-in {
  animation: fadeIn 0.8s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 悬浮效果 */
.hover-float {
  transition: transform 0.3s ease;
}

.hover-float:hover {
  transform: translateY(-6px);
}

/* 渐变背景动画 */
.gradient-bg {
  background: linear-gradient(45deg, #1890ff, #722ed1);
  background-size: 200% 200%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 技术图标旋转动画 */
.tech-icon {
  transition: transform 0.3s ease;
}

.tech-icon:hover {
  transform: rotate(360deg);
}

/* 卡片阴影动画 */
.ant-card {
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.ant-card:hover {
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  transform: translateY(-5px);
} 