.site-header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: linear-gradient(90deg, #001529 0%, #002140 100%);
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  backdrop-filter: blur(8px);
}

.header-logo {
  display: flex;
  align-items: center;
  margin-right: 48px;
}

.logo-icon {
  font-size: 28px;
  color: #1890ff;
  margin-right: 12px;
  animation: compass-spin 3s linear infinite;
}

.logo-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  background: linear-gradient(120deg, #1890ff, #722ed1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
}

.site-menu {
  flex: 1;
  border: none;
  background: transparent;
}

.ant-menu-item {
  padding: 0 20px;
  font-size: 15px;
  transition: all 0.3s ease;
}

.ant-menu-item:hover {
  background: rgba(24, 144, 255, 0.1);
}

.ant-menu-item .anticon {
  margin-right: 8px;
  font-size: 16px;
  transition: transform 0.3s ease;
}

.ant-menu-item:hover .anticon {
  transform: translateY(-2px);
}

@keyframes compass-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 响应式调整 */
@media (max-width: 768px) {
  .site-header {
    padding: 0 16px;
  }

  .header-logo {
    margin-right: 24px;
  }

  .logo-text {
    font-size: 18px;
  }

  .ant-menu-item {
    padding: 0 12px;
    font-size: 14px;
  }
} 